.Experience a {
    color: rgb(38,198,218);
    font-weight: bold;
    border: solid 1px;
    padding: 4px;
    border-radius: 6px;
}

.Experience a:hover, a:visited {
    background-color: lightgrey;
    color: rgb(38,198,218);
}