.SocialLinks {
    margin: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    text-align: center;
    list-style: none;
}

.SocialLinks img {
    width: 40px;
    height: 40px;
    display: inline;
}