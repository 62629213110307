.About a {
    color: rgb(38,198,218);
    border: solid white 1px;
    
}

.nav-tabs .nav-link.active {
    background-color: #40a4c8 !important;
}

.About a:hover {
    color: #40a4c8;
}

.About a:active,
.About a.active {
    color: white;
    background-color: rgb(38,198,218);
}

.About li {
    list-style: circle;
    padding: 4px;
}