.Education {

}

.Education p {
    padding: 5px 50px 5px 50px;
}

@media (max-width: 600px) {
    .Education p {
        padding: 5px 20px 5px 20px;
    }
}